import React from "react";
import Layout from "../components/Layout";
import Registration from '../components/register'



const Register = () => {
  return (
    <Layout>
      <Registration />
    </Layout>
  );
};

export default Register;
